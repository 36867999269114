@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'Satoshi';
	src: url('./fonts/Satoshi-Variable.woff2') format('woff2');
	font-weight: 300 900;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'Satoshi';
	src: url('./fonts/Satoshi-VariableItalic.woff2') format('woff2');
	font-weight: 300 900;
	font-display: swap;
	font-style: italic;
}

* {
	font-family: 'Satoshi';
	font-weight: 400;
}

::-webkit-scrollbar {
	display: none;
}

::-moz-scrollbar {
	display: none;
}
